const Score = ({ animate, lastAnswerResult, score }) => {
  return (
    <div className={`scoreWrapper ${animate ? lastAnswerResult : ""}`}>
      <div className="score">
        <h2>{score}</h2>
      </div>
    </div>
  );
};

export default Score;
