const questions = [
  {
    category: "bonus",
    question:
      "What is the only planet in our solar system that rotates on its side?",
    correctAnswer: "Uranus",
    incorrectAnswers: ["Venus", "Jupiter", "Neptune"],
  },
  {
    category: "bonus",
    question: "Which mammal can fly without feathers?",
    correctAnswer: "Bat",
    incorrectAnswers: ["Bird", "Butterfly", "Flying squirrel"],
  },
  {
    category: "bonus",
    question: "What's the smallest bone in the human body?",
    correctAnswer: "Stapes",
    incorrectAnswers: ["Femur", "Tibia", "Radius"],
  },
  {
    category: "bonus",
    question: "Which element gives the blood its red color?",
    correctAnswer: "Iron",
    incorrectAnswers: ["Oxygen", "Carbon", "Hydrogen"],
  },
  {
    category: "bonus",
    question: "What's the rarest blood type among humans?",
    correctAnswer: "O-negative",
    incorrectAnswers: ["A", "B", "AB"],
  },
  {
    category: "bonus",
    question: "What is the fear of the number 13 called?",
    correctAnswer: "Triskaidekaphobia",
    incorrectAnswers: [
      "Hexakosioihexekontahexaphobia",
      "Octophobia",
      "Phobophobia",
    ],
  },
  {
    category: "bonus",
    question: "Which is the only mammal capable of true flight?",
    correctAnswer: "Bat",
    incorrectAnswers: ["Bird", "Flying squirrel", "Pegasus"],
  },
  {
    category: "bonus",
    question: "How many time zones are there in the world?",
    correctAnswer: "24",
    incorrectAnswers: ["12", "36", "48"],
  },
  {
    category: "bonus",
    question: "What is the smallest country in the world?",
    correctAnswer: "Vatican City",
    incorrectAnswers: ["Monaco", "Nauru", "Liechtenstein"],
  },
  {
    category: "bonus",
    question: "What is the rarest eye color among humans?",
    correctAnswer: "Amber",
    incorrectAnswers: ["Brown", "Blue", "Green"],
  },
  {
    category: "bonus",
    question: "What's the smallest planet in our solar system?",
    correctAnswer: "Mercury",
    incorrectAnswers: ["Venus", "Earth", "Mars"],
  },
  {
    category: "bonus",
    question: "What's the rarest natural hair color among humans?",
    correctAnswer: "Red",
    incorrectAnswers: ["Black", "Brown", "Blonde"],
  },
  {
    category: "bonus",
    question: "Which gas do plants primarily use for photosynthesis?",
    correctAnswer: "Carbon dioxide",
    incorrectAnswers: ["Oxygen", "Nitrogen", "Hydrogen"],
  },
  {
    category: "bonus",
    question: "What's the largest species of shark?",
    correctAnswer: "Whale shark",
    incorrectAnswers: ["Great white shark", "Tiger shark", "Hammerhead shark"],
  },
  {
    category: "bonus",
    question: "What is the fear of the number 666 called?",
    correctAnswer: "Hexakosioihexekontahexaphobia",
    incorrectAnswers: ["Triskaidekaphobia", "Octophobia", "Phobophobia"],
  },
  {
    category: "bonus",
    question: "What's the tallest mountain in the world?",
    correctAnswer: "Mount Everest",
    incorrectAnswers: ["Mount Kilimanjaro", "Mount McKinley", "K2"],
  },
  {
    category: "bonus",
    question: "Which planet is known as the 'Morning Star' or 'Evening Star'?",
    correctAnswer: "Venus",
    incorrectAnswers: ["Mercury", "Mars", "Jupiter"],
  },
  {
    category: "bonus",
    question: "What's the longest river in the world?",
    correctAnswer: "Nile",
    incorrectAnswers: ["Amazon", "Yangtze", "Mississippi"],
  },
  {
    category: "bonus",
    question: "What's the largest internal organ in the human body?",
    correctAnswer: "Liver",
    incorrectAnswers: ["Heart", "Lung", "Brain"],
  },
  {
    category: "bonus",
    question: "In which year did the United States declare independence?",
    correctAnswer: "1776",
    incorrectAnswers: ["1492", "1865", "1945"],
  },
  {
    category: "bonus",
    question:
      "Which element is a noble gas and emits a reddish-orange glow in neon signs?",
    correctAnswer: "Neon",
    incorrectAnswers: ["Argon", "Krypton", "Xenon"],
  },
  {
    category: "bonus",
    question: "What's the largest moon in our solar system?",
    correctAnswer: "Ganymede",
    incorrectAnswers: ["Titan", "Europa", "Callisto"],
  },
  {
    category: "bonus",
    question: "What's the national bird of the United States?",
    correctAnswer: "Bald eagle",
    incorrectAnswers: ["American robin", "Peregrine falcon", "Wild turkey"],
  },
  {
    category: "bonus",
    question: "What's the longest bone in the human body?",
    correctAnswer: "Femur",
    incorrectAnswers: ["Tibia", "Fibula", "Radius"],
  },
  {
    category: "bonus",
    question: "What's the largest desert in the world?",
    correctAnswer: "Antarctica",
    incorrectAnswers: ["Sahara", "Arabian Desert", "Gobi Desert"],
  },
  {
    category: "bonus",
    question: "What's the highest mountain in North America?",
    correctAnswer: "Denali",
    incorrectAnswers: ["Mount St. Elias", "Mount Logan", "Mount Foraker"],
  },
  {
    category: "bonus",
    question: "What's the world's largest land animal?",
    correctAnswer: "African elephant",
    incorrectAnswers: ["White rhinoceros", "Hippopotamus", "Kevin Malone"],
  },
  {
    category: "bonus",
    question: "What's the national flower of India?",
    correctAnswer: "Lotus",
    incorrectAnswers: ["Rose", "Tulip", "Jasmine"],
  },
  {
    category: "bonus",
    question: "Which planet has the most moons in our solar system?",
    correctAnswer: "Jupiter",
    incorrectAnswers: ["Saturn", "Uranus", "Neptune"],
  },
  {
    category: "bonus",
    question: "What's the largest species of penguin?",
    correctAnswer: "Emperor penguin",
    incorrectAnswers: ["King penguin", "Gentoo penguin", "Adélie penguin"],
  },
  {
    category: "bonus",
    question: "What's the national flower of Japan?",
    correctAnswer: "Cherry blossom",
    incorrectAnswers: ["Rose", "Lily", "Tulip"],
  },
  {
    category: "bonus",
    question: "What is the largest organ in the human body?",
    correctAnswer: "Skin",
    incorrectAnswers: ["Heart", "Liver", "Lung"],
  },
  {
    category: "bonus",
    question: "What's the main ingredient in traditional Japanese miso soup?",
    correctAnswer: "Soybean",
    incorrectAnswers: ["Seaweed", "Tofu", "Fish"],
  },
  {
    category: "bonus",
    question: "What's the largest species of bear?",
    correctAnswer: "Kodiak bear",
    incorrectAnswers: ["Polar bear", "Grizzly bear", "Black bear"],
  },
  {
    category: "bonus",
    question: "What's the longest river in Europe?",
    correctAnswer: "Volga",
    incorrectAnswers: ["Danube", "Rhine", "Elbe"],
  },
  {
    category: "bonus",
    question: "What's the tallest building in the world?",
    correctAnswer: "Burj Khalifa",
    incorrectAnswers: ["Shanghai Tower", "Big Ben", "Taipei 101"],
  },
  {
    category: "bonus",
    question: "Which gas is responsible for the color of the Earth's sky?",
    correctAnswer: "Nitrogen",
    incorrectAnswers: ["Oxygen", "Carbon dioxide", "Hydrogen"],
  },
  {
    category: "bonus",
    question: "What's the national animal of Canada?",
    correctAnswer: "Beaver",
    incorrectAnswers: ["Moose", "Caribou", "Grizzly bear"],
  },
  {
    category: "bonus",
    question: "What's the largest species of eagle?",
    correctAnswer: "Harpy eagle",
    incorrectAnswers: ["Bald eagle", "Golden eagle", "African fish eagle"],
  },
  {
    category: "bonus",
    question: "Which European city is known as the 'Eternal City'?",
    correctAnswer: "Rome",
    incorrectAnswers: ["Paris", "Athens", "Barcelona"],
  },
  {
    category: "bonus",
    question: "What's the smallest ocean on Earth?",
    correctAnswer: "Arctic Ocean",
    incorrectAnswers: ["Atlantic Ocean", "Indian Ocean", "Southern Ocean"],
  },
  {
    category: "bonus",
    question: "What's the national sport of Japan?",
    correctAnswer: "Sumo wrestling",
    incorrectAnswers: ["Judo", "Karate", "Baseball"],
  },
  {
    category: "bonus",
    question: "What is one tenth of a nautical mile also known as?",
    correctAnswer: "Cable",
    incorrectAnswers: ["Wire", "Strop", "Bolt"],
  },
  {
    category: "bonus",
    question:
      "How many runs did cricketer Jack Leach score in his legendary innings?",
    correctAnswer: "1",
    incorrectAnswers: ["100", "250", "500"],
  },
  {
    category: "bonus",
    question:
      "Our product and our sum always give the same answer. Who are we?",
    correctAnswer: "1, 2, 3",
    incorrectAnswers: ["10, 20, 30", "0, 1, 2", "1, 1, 1"],
  },
  {
    category: "bonus",
    question: "What's the lifespan of a dragonfly?",
    correctAnswer: "Several months",
    incorrectAnswers: ["A few days", "A year", "Decades"],
  },
  {
    category: "bonus",
    question: "What's the main ingredient in dynamite?",
    correctAnswer: "Nitroglycerin",
    incorrectAnswers: ["Gunpowder", "TNT", "C4"],
  },
  {
    category: "bonus",
    question: "What's the largest species of penguin?",
    correctAnswer: "Emperor penguin",
    incorrectAnswers: ["King penguin", "Gentoo penguin", "XXL penguin"],
  },
  {
    category: "bonus",
    question: "Which of these is not a brand of loo roll?",
    correctAnswer: "Rumproll",
    incorrectAnswers: ["Andrex", "Cushelle", "Quilton"],
  },
  {
    category: "bonus",
    question: "Which of these fruits is a berry?",
    correctAnswer: "Banana",
    incorrectAnswers: ["Strawberry", "Kiwi", "Pineapple"],
  },
  {
    category: "bonus",
    question: "What shape is wombat poop?",
    correctAnswer: "Cube-shaped",
    incorrectAnswers: ["Round", "Cylindrical", "Triangular"],
  },
  {
    category: "bonus",
    question: "How much taller do astronaughts become in space?",
    correctAnswer: "2 inches",
    incorrectAnswers: ["1 inch", "3 inches", "4 inches"],
  },
];

export default questions;
