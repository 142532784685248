const colors = {
  music: "#FF9AD3",
  sport_leisure: "#57F1FF",
  film_tv: "#8C4EFF",
  arts_literature: "#FF871D",
  geography: "#256812",
  history: "#1A87ED",
  food_drink: "#38c977",
  society_culture: "#F1BE00",
  science: "#FC3C3C",
  bonus: "var(--gold)",
  general_knowledge: "var(--multi)",
};

export default colors;
