import { useMemo, useState, useEffect } from "react";

const Answers = ({ item, answerHandler, itemIndex }) => {
  const [lastAnswer, setLastAnswer] = useState(null);
  const [renderButtons, setRenderButtons] = useState(false);
  const answers = useMemo(() => {
    return [item.correctAnswer, ...item.incorrectAnswers].sort(
      () => 0.5 - Math.random()
    );
  }, [item]);

  useEffect(() => {
    if (item) {
      setTimeout(() => {
        setRenderButtons(true);
      }, 600);
    }
  }, [item]);

  const handleAnswer = (event, answer) => {
    event.stopPropagation();
    setLastAnswer(answer);
    answerHandler(itemIndex, answer);
  };

  const buttonStyles = (answer) => {
    if (!lastAnswer) return "";
    if (answer === item.correctAnswer) return "correctBtn";
    if (lastAnswer === answer) return "wrongBtn";
    return "";
  };

  return (
    <div className="buttons">
      {renderButtons &&
        answers.map((answer, answerIndex) => (
          <button
            key={answerIndex}
            onClick={(event) => handleAnswer(event, answer)}
            className={buttonStyles(answer)}
            disabled={lastAnswer}
          >
            {answer}
          </button>
        ))}
    </div>
  );
};

export default Answers;
