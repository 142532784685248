import "./css/styles.css";
import "./css/options.css";
import { useState, useEffect, useRef } from "react";
import { usePostHog } from "posthog-js/react";
import axios from "axios";
import { ReactComponent as Options } from "./images/gear.svg";
import Score from "./components/Score";
import Cube from "./components/Cube";
import Name from "./components/Name";
import difficultyMapper from "./utils/difficultyMapper";
import { LS_TRIVLE_NAME } from "./utils/constants";
import funnyMusic from "./utils/funnyMusic";
import customQuestions from "./questions";

const client = axios.create({
  baseURL: process.env.REACT_APP_TRIVIA_API,
  timeout: 1000
});

export default function App() {
  const posthog = usePostHog();
  const [activeItem, setActiveItem] = useState(null);
  const [deactiveItem, setDeactiveItem] = useState(null);
  const [playerName, setPlayerName] = useState(
    localStorage.getItem(LS_TRIVLE_NAME)
  );
  const [gameStarted, setGameStarted] = useState(false);
  const [score, setScore] = useState(0);
  const [lastAnswerResult, setLastAnswerResult] = useState(null);
  const [items, setItems] = useState([]);
  const [highscores, setHighscores] = useState([]);
  const [loadingScores, setLoadingScores] = useState(false);
  const nextQuestionRef = useRef(null);
  const replacementQuestionsRef = useRef(null);
  const [showCategories, setShowCategories] = useState(true);
  const [modalOpen, setModalOpen] = useState(null);
  const [backupQuestions, setBackupQuestions] = useState([]);

  const populateBackupQuestions = async () => {
    const newQuestions = await fetchQuestions(50);
    const formattedNewQuestions = formatQuestions(newQuestions);
    setBackupQuestions(formattedNewQuestions);
  };

  const formatQuestions = (questions, fixedIndex) => {
    const formattedResponse = questions.map((data, index) => {
      const difficulty = data.difficulty || "medium";
      let points = data.points;
      if (data.category === "General Knowledge") {
        points = 5;
      } else if (data.category === "bonus") {
        points = 10;
      } else {
        points = difficultyMapper[difficulty];
      }
      const incorrectAnswers =
        data.category === "Music"
          ? funnyMusic(data.incorrectAnswers)
          : data.incorrectAnswers;
      return {
        ...data,
        index: fixedIndex ? fixedIndex : index,
        difficulty,
        points,
        incorrectAnswers,
      };
    });
    return formattedResponse;
  };

  const fetchQuestions = async (limit = 1) => {
    let questions;
    try {
      const response = await client.get(`/questions?limit=${limit}`);
      questions = response.data.filter((data) => {
        let questionLengthPass = false;
        let answersLengthPass = false;
        const invalidStrings = "study of";
        const regex = new RegExp("(\\w*" + invalidStrings + "\\w*)", "gi");
        const matches = data.question.match(regex);
        if (data.question.length < 130 && !matches) questionLengthPass = true;
        const longAnswerExists = [
          data.correctAnswer,
          ...data.incorrectAnswers,
        ].find((answer) => answer.length > 40);
        if (!longAnswerExists) {
          answersLengthPass = true;
        }
        return questionLengthPass && answersLengthPass;
      });
      if (questions.length < limit) {
        questions.push(...(await fetchQuestions(limit - questions.length)));
      }
    } catch (e) {
      if (backupQuestions.length && limit === 1) {
        const objectToReturn = backupQuestions?.[0];
      
        // Create a copy of the array without the first object
        const updatedQuestions = backupQuestions.slice(1);
        
        // Update the state with the remaining objects
        setBackupQuestions(updatedQuestions);
        questions = [objectToReturn];
      }
    }

    return questions;
  };

  useEffect(() => {
    const getBackupQuestions = async () => {
      await populateBackupQuestions();
    }
    if (backupQuestions.length < 5) {
      getBackupQuestions();
    }
  }, [backupQuestions])

  useEffect(() => {
    const getQuestions = async () => {
      const fetchedQuestions = await fetchQuestions(9);
      const formattedQuestions = formatQuestions(fetchedQuestions);
      setItems(formattedQuestions);
    };
    getQuestions();
  }, []);

  const handleGameOver = async () => {
    setLoadingScores(true);
    posthog?.capture("Game Over", { score });
    const response = await axios.post(
      `${process.env.REACT_APP_PUBLIC_URL}/api/newScore`,
      {
        playerName,
        score,
      }
    );
    setHighscores(response.data.data);
    setLoadingScores(false);
  };

  const handleOpen = (index) => {
    setActiveItem(index);
  };

  const fetchNextQuestion = async (index) => {
    const fetchedQuestions = await fetchQuestions(1);
    let formattedQuestion = formatQuestions(fetchedQuestions)[0];
    const r = Math.random();
    if (r < 0.015) {
      formattedQuestion = formatQuestions([
        customQuestions[Math.floor(Math.random() * customQuestions.length)],
      ])[0];
    } else if (
      r < 0.095 &&
      !items.find((i) => i.bonus?.name === "replace") &&
      items.filter((i) => !i.dead).length > 1
    ) {
      formattedQuestion.bonus = {
        name: "replace",
      };
      const activeItems = items.filter((i) => !i.dead).length;
      if (activeItems) {
        const fetchedQuestions = await fetchQuestions(activeItems);
        let formattedQuestions = formatQuestions(fetchedQuestions);
        replacementQuestionsRef.current = formattedQuestions;
      }
    }
    nextQuestionRef.current = formattedQuestion;
  };

  const handleClose = async (
    index,
    answerIsCorrect,
    replaceAll,
    restart = false
  ) => {
    setActiveItem(null);
    setDeactiveItem(index);
    setLastAnswerResult(null);
    if (!restart) {
      setTimeout(async () => {
        if (answerIsCorrect) {
          if (replaceAll) {
            setItems((prev) =>
              [...prev].map((question, i) => {
                if (!question.dead) {
                  const newQ = {
                    ...replacementQuestionsRef.current.splice(0, 1)[0],
                    index: i,
                  };
                  return newQ;
                } else {
                  return question;
                }
              })
            );
          } else {
            setItems((prev) =>
              [...prev].map((question, i) => {
                if (i === index) return nextQuestionRef.current;
                return question;
              })
            );
          }
        } else {
          setItems((prev) =>
            [...prev].map((item, itemIndex) =>
              itemIndex === index ? { ...item, dead: !answerIsCorrect } : item
            )
          );
        }
        setDeactiveItem(null);
      }, 500);
    }
  };

  const handleAnswer = async (index, answer) => {
    const item = items[activeItem];
    const answerIsCorrect = item.correctAnswer === answer;
    if (answerIsCorrect) {
      if (item.bonus?.name !== "replace") {
        setScore((prevScore) => prevScore + item.points);
      }
      setLastAnswerResult("correct");
    } else {
      setLastAnswerResult("wrong");
    }
    setTimeout(async () => {
      await handleClose(index, answerIsCorrect, item.bonus?.name === "replace");
    }, 1000);
    if (answerIsCorrect) {
      await fetchNextQuestion(index);
    }
  };

  const handleRestart = async () => {
    const fetchedQuestions = await fetchQuestions(9);
    const formattedQuestions = formatQuestions(fetchedQuestions);
    setItems(formattedQuestions);
    setScore(0);
  };

  const handleStart = () => {
    setGameStarted(true);
  };

  const fetchAccessToken = async () => {
    await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/getAccess`);
  };

  const openOptionsModal = (e) => {
    e.stopPropagation();
    setModalOpen(!modalOpen);
  };

  const toggleCategories = () => {
    setShowCategories(!showCategories);
  };

  useEffect(() => {
    if (gameStarted) {
      fetchAccessToken();
    }
  }, [gameStarted]);

  return (
    <>
      <div
        className="app bg"
        onClick={() => {
          if (modalOpen !== null) setModalOpen(false);
        }}
      >
        <div className="header">
          <Options
            width={30}
            height={30}
            className="options"
            onClick={(e) => openOptionsModal(e)}
          />
        </div>
        <h1>Trivle.io</h1>
        {!gameStarted ? (
          <Name
            startHandler={handleStart}
            playerName={playerName}
            setPlayerName={setPlayerName}
          />
        ) : (
          <>
            <Score
              animate={lastAnswerResult !== null}
              score={score}
              lastAnswerResult={lastAnswerResult}
            />
            <Cube
              openHandler={handleOpen}
              answerHandler={handleAnswer}
              items={items}
              activeItem={activeItem}
              deactiveItem={deactiveItem}
              restartHandler={handleRestart}
              gameOverHandler={handleGameOver}
              highscores={highscores}
              loadingScores={loadingScores}
              showCategories={showCategories}
            />
          </>
        )}
      </div>
      <div
        className={`modal ${
          modalOpen !== null && (modalOpen ? "slideIn" : "slideOut")
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modalOption">
          <p>Show category names</p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={toggleCategories}
              checked={showCategories}
            />
            <span className="slider"></span>
          </label>
        </div>
        {gameStarted && (
          <div className="modalOption">
            <button
              className="niceButton"
              style={{ margin: "5px auto" }}
              onClick={() => {
                handleClose(null, null, null, true);
                handleRestart();
                setModalOpen(false);
              }}
            >
              Restart
            </button>
          </div>
        )}
      </div>
    </>
  );
}
