import { ReactComponent as Loading } from "../images/loading.svg";

const GameOver = ({ restartHandler, highscores, loadingScores }) => {
  const handleRestart = () => {
    restartHandler();
  };
  return (
    <div className="gameOver">
      <p>Game Over</p>
      <div className="leaderboard">
        <h2>High Scores</h2>
        {loadingScores && <Loading />}
        {!loadingScores &&
          highscores.map((user, index) => (
            <div className="row" key={index}>
              <div className="rank">{user.rank}</div>
              <div className="name">{user.name}</div>
              <div className="scorevalue">{user.value}</div>
            </div>
          ))}
      </div>
      <button className="niceButton" onClick={handleRestart}>Restart</button>
    </div>
  );
};

export default GameOver;
