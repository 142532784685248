import { createRoot } from "react-dom/client";
import { PostHogProvider } from "posthog-js/react";

import App from "./App";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  session_recording: {
    maskAllInputs: false,
  },
};

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <PostHogProvider
    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
    options={options}
  >
    <App />
  </PostHogProvider>
);
