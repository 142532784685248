import { useMemo, useEffect } from "react";
import colors from "./../utils/colors";
import Answers from "./Answers";
import GameOver from "./GameOver";

const Cube = ({
  items,
  activeItem,
  deactiveItem,
  openHandler,
  answerHandler,
  restartHandler,
  gameOverHandler,
  highscores,
  loadingScores,
  showCategories,
}) => {
  const anyItemActive = activeItem !== null;
  const anyItemDeactive = deactiveItem !== null;

  const gameOver = useMemo(() => {
    if (items.length === 0) return false;
    return !items.find((item) => !item.dead);
  }, [items]);

  useEffect(() => {
    if (gameOver) {
      gameOverHandler();
    }
  }, [gameOver]);

  return (
    <div className="cubeWrapper">
      <div className="cube">
        {gameOver && (
          <GameOver
            restartHandler={restartHandler}
            highscores={highscores}
            loadingScores={loadingScores}
          />
        )}
        <div className="cubeContent">
          {items.map((item, itemIndex) => {
            const thisItemActive = activeItem === itemIndex;
            const anotherItemActive = anyItemActive && !thisItemActive;
            const thisItemDeactive = deactiveItem === itemIndex;
            const anoterItemDeactive = anyItemDeactive && !thisItemDeactive;
            const itemClassNames = `item ${anotherItemActive ? "hidden" : ""} ${
              thisItemActive ? "active" : ""
            } ${thisItemDeactive ? "deactive" : ""} ${
              anoterItemDeactive ? "unhidden" : ""
            }`;
            const itemCategory = item.category
              .toLowerCase()
              .replace(/\W+/g, "_");
            let itemColor = item.dead ? "transparent" : colors[itemCategory];

            return (
              <div
                key={itemIndex}
                onClick={
                  deactiveItem === null && !item.dead
                    ? () => openHandler(itemIndex)
                    : null
                }
                className={itemClassNames}
                style={{
                  background: itemColor,
                  cursor: item.dead ? "default" : "pointer",
                }}
              >
                {!thisItemActive && !thisItemDeactive && !item.dead && (
                  <>
                    {item.bonus?.name === "replace" ? (
                      <img src="/replace.png" width="30px" />
                    ) : (
                      <>
                        {!anyItemActive &&
                          !anyItemDeactive &&
                          showCategories && (
                            <span className="smallCategoryName">
                              {item.category}
                            </span>
                          )}
                        <p className="points">{item.points}</p>
                      </>
                    )}
                  </>
                )}
                {thisItemActive && (
                  <div className="itemContent">
                    <p className="category">{item.category}</p>
                    <div className="question">
                      <p>{item.question}</p>
                    </div>
                    <Answers
                      item={item}
                      answerHandler={answerHandler}
                      itemIndex={itemIndex}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Cube;
