import { useState } from "react";
import { LS_TRIVLE_NAME } from "../utils/constants";
import { usePostHog } from "posthog-js/react";

const Name = ({ startHandler, playerName, setPlayerName }) => {
  const posthog = usePostHog();
  const [error, setError] = useState(false);

  const handleStart = (e) => {
    e.preventDefault();
    if (playerName.length < 3) {
      setError(true);
    } else {
      posthog?.identify(playerName, { name: playerName });
      localStorage.setItem(LS_TRIVLE_NAME, playerName);
      startHandler();
    }
  };

  return (
    <form onSubmit={(e) => handleStart(e)}>
      <label htmlFor="name">Name</label>
      <input
        id="name"
        name="player_name"
        type="text"
        onChange={(e) => {
          setError(false);
          setPlayerName(e.target.value);
        }}
        value={playerName || ""}
        autoComplete="off"
        aria-autocomplete="none"
        spellCheck="false"
        minLength="3"
        error={error.toString()}
      />
      <input type="submit" value="Play" />
    </form>
  );
};

export default Name;
